import { configureStore, combineReducers } from "@reduxjs/toolkit";

import { authApi } from "./services/authApi";
import { roomApi } from "./services/roomsApi";
import { userApi } from "./services/usersApi";
import { deviceApi } from "./services/deviceApi";
import { faqApi } from "./services/faqApi";
import { ticketApi } from "./services/ticketApi";
import { alertsApi } from "./services/alertsApi";
import { reportApi } from "./services/reportsApi";
import { firmwareApi } from "./services/firmwareApi";
//
import { manufacturerApi } from "./services/manufacturerApi";
import { boardsApi } from "./services/boardsApi";
import { componentsApi } from "./services/componentsApi";

import authReducer from "./features/authSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { rtkQueryErrorLogger } from "../middlewares/error-handler";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

// First, combine reducers
const rootReducer = combineReducers({
  auth: authReducer,
  [authApi.reducerPath]: authApi.reducer,
  [roomApi.reducerPath]: roomApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [deviceApi.reducerPath]: deviceApi.reducer,
  [faqApi.reducerPath]: faqApi.reducer,
  [ticketApi.reducerPath]: ticketApi.reducer,
  [alertsApi.reducerPath]: alertsApi.reducer,
  [reportApi.reducerPath]: reportApi.reducer,
  [firmwareApi.reducerPath]: firmwareApi.reducer,
  [manufacturerApi.reducerPath]: manufacturerApi.reducer,
  [boardsApi.reducerPath]: boardsApi.reducer,
  [componentsApi.reducerPath]: componentsApi.reducer,
});

// Then, wrap this combined reducer with persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      roomApi.middleware,
      deviceApi.middleware,
      userApi.middleware,
      faqApi.middleware,
      ticketApi.middleware,
      alertsApi.middleware,
      reportApi.middleware,
      firmwareApi.middleware,
      manufacturerApi.middleware,
      boardsApi.middleware,
      componentsApi.middleware,
      rtkQueryErrorLogger
    ),
});

export const persistor = persistStore(store);
