import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const componentsApi = createApi({
  reducerPath: "componentsApi",
  // configuring baseQuery
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL,
    prepareHeaders: (headers, { getState }) => {
      console.log("getState", getState);
      const token = JSON.parse(localStorage.getItem("user") || "").token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
        headers.set("targetUserID", getState().auth.targetUserID);
      }
    },
  }),
  endpoints: (builder) => ({
    // check if the user has room
    listComponents: builder.mutation({
      query: () => {
        return {
          url: `/component/`,
          method: "get",
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    createComponent: builder.mutation({
      query: (body) => {
        return {
          url: `/component`,
          method: "post",
          body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    massCreateComponent: builder.mutation({
      query: (body) => {
        return {
          url: `/component/mass`,
          method: "post",
          body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    autoGenerateComponent: builder.mutation({
      query: (query) => {
        return {
          url: `/component/massSerial`,
          method: "post",
          body: query.body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    rejectComponent: builder.mutation({
      query: (query) => {
        return {
          url: `/component/reject/${query.componentId}`,
          method: "put",
          body: query.body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    repairComponent: builder.mutation({
      query: (query) => {
        return {
          url: `/component/repair/${query.rejectionId}`,
          method: "put",
          body: query.body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    getComponentReworkStatus: builder.mutation({
      query: (query) => {
        return {
          url: `/component/rework/${query.componentId}`,
          method: "get",
          body: query.body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
  }),
});

export const {
  useAutoGenerateComponentMutation,
  useCreateComponentMutation,
  useGetComponentReworkStatusMutation,
  useListComponentsMutation,
  useMassCreateComponentMutation,
  useRejectComponentMutation,
  useRepairComponentMutation,
} = componentsApi;
