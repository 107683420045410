import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const boardsApi = createApi({
  reducerPath: "boardsApi",
  // configuring baseQuery
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL,
    prepareHeaders: (headers, { getState }) => {
      console.log("getState", getState);
      const token = JSON.parse(localStorage.getItem("user") || "").token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
        headers.set("targetUserID", getState().auth.targetUserID);
      }
    },
  }),
  endpoints: (builder) => ({
    // check if the user has room
    listboard: builder.mutation({
      query: (query) => {
        return {
          url: `/board/`,
          method: "get",
          params: query,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    massCreateBoard: builder.mutation({
      query: (body) => {
        return {
          url: `/board/mass`,
          method: "post",
          body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    addSensorOnBoard: builder.mutation({
      query: (query) => {
        return {
          url: `/board/${query.boardId}`,
          method: "put",
          body: query.body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    rejectBoard: builder.mutation({
      query: (query) => {
        return {
          url: `/board/reject/${query.boardId}`,
          method: "put",
          body: query.body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    repairBoard: builder.mutation({
      query: (query) => {
        return {
          url: `/board/repair/${query.rejectionId}`,
          method: "put",
          body: query.body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    getReworkStatus: builder.mutation({
      query: (query) => {
        return {
          url: `/board/rework/${query.boardId}`,
          method: "get",
          body: query.body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
  }),
});

export const {
  useAddSensorOnBoardMutation,
  useGetReworkStatusMutation,
  useListboardMutation,
  useMassCreateBoardMutation,
  useRejectBoardMutation,
  useRepairBoardMutation,
} = boardsApi;
