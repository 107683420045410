import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useToast } from "@chakra-ui/react";
import moment from "moment";
import {
  useListboardMutation,
  useMassCreateBoardMutation,
  useRejectBoardMutation,
  useRepairBoardMutation,
  useAddSensorOnBoardMutation,
  useGetReworkStatusMutation,
} from "../../../store/services/boardsApi";
import { useListComponentsMutation } from "../../../store/services/componentsApi";
import { useListManufacturerMutation } from "../../../store/services/manufacturerApi";
import CustomDropdown from "../../../components/common/CustomDropdown";
import Modal from "../../../components/common/Modal";

const initialValues = {
  batch: "",
  numberOfBoards: 0,
  boardType: "ib",
  manufacturer: "",
  dateReceived: "",
  orderDate: "",
  orderedFrom: "",
};

const ListBoards = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const [listBoards, { data: boardList, isSuccess: boardSuccess }] = useListboardMutation();
  const [createBoards, { data: boardCreateData, isSuccess: isBoardSuccess, error: boardError, isError: isBoardError }] = useMassCreateBoardMutation();
  const [listManufacturer, { data: manufacturerList, isSuccess: manufacturerListSuccess }] = useListManufacturerMutation();
  const [listComponent, { data: componentList, isSuccess: componentListSuccess }] = useListComponentsMutation();

  const [components, setComponents] = useState([]);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: initialValues,
    // validationSchema: roomsSchema,
    onSubmit: (values, action) => {
      console.log(values);

      createBoards(values);
      action.resetForm();
    },
  });

  const [filter, setFilter] = useState({
    boardType: "all",
    boardNumber: "",
    batch: "",
    boardStatus: "all",
  });

  const [boards, setBoards] = useState([]);
  const [addBoardModal, setAddBoardModal] = useState(false);
  const [activeBoard, setActiveBoard] = useState();

  const getComponentList = async (component) => {
    let newComponents = await listComponent({ isBeingUsed: false, componentStatus: ["working", "repaired"], component });
    console.log("components --> ", components);
    setComponents(
      newComponents.data.map((com) => {
        return { value: com._id, label: com.batch + " - " + com.componentNumber };
      })
    );
    // return [];
  };

  useEffect(() => {
    if (boardSuccess) setBoards(boardList);
  }, [boardList, boardSuccess]);

  useEffect(() => {
    listBoards();
    listManufacturer();
  }, []);

  useEffect(() => {
    listBoards(filter);
  }, [filter]);

  useEffect(() => {
    if (isBoardSuccess) {
      toast({
        description: "board created successfully",
        duration: 5000,
        isClosable: true,
        status: "success",
      });
      setAddBoardModal(false);
      listBoards(filter);
    }
  }, [boardCreateData, isBoardSuccess]);

  return (
    <div className={`pb-5 w-screen m-auto fixed bg-white left-0 z-50 h-screen top-0 mt-16`}>
      <div className="topbar   flex justify-between items-center border-b-2 pb-3 mt-3 px-10 m-auto">
        <h1 className="text-xl font-semibold">Boards</h1>
        <div className=" gap-5 flex flex-wrap justify-end">
          <button onClick={() => {}} className="rounded-lg border-[1px] h-10 px-5 border-black font-semibold">
            Components
          </button>
          <button
            onClick={() => {
              setAddBoardModal(true);
            }}
            className="rounded-lg border-[1px] h-10 px-5 border-black font-semibold "
          >
            Add Board
          </button>
        </div>
      </div>

      {addBoardModal && (
        <Modal onClose={() => setAddBoardModal(false)}>
          <h1 className="text-xl font-semibold">Add Boards</h1>

          <div className="inpGrp text-left relative">
            <form className="gap-4 flex flex-col mt-5" onSubmit={handleSubmit}>
              <div className="flex gap-5">
                <div className="w-1/2">
                  <label className=" font-semibold">Number Of Boards</label>
                  <input
                    name="numberOfBoards"
                    id="numberOfBoards"
                    value={values.numberOfBoards}
                    onChange={handleChange}
                    type="number"
                    min={0}
                    placeholder=""
                    className="bg-[#F5F5F5] focus:ring-0 focus:outline-black font-semibold px-2 py-2 rounded-md w-full"
                  />
                  <span className="text-red-500 text-xs absolute -bottom-4 left-0">{errors.numberOfBoards && touched.numberOfBoards ? errors.numberOfBoards : ""}</span>
                </div>
                <div className="w-1/2">
                  <label className=" font-semibold">Batch</label>
                  <input
                    name="batch"
                    id="batch"
                    value={values.batch}
                    min={0}
                    onChange={handleChange}
                    type="number"
                    placeholder=""
                    className="bg-[#F5F5F5] focus:ring-0 focus:outline-black font-semibold px-2 py-2 rounded-md w-full"
                  />
                  <span className="text-red-500 text-xs absolute -bottom-4 left-0">{errors.batch && touched.batch ? errors.batch : ""}</span>
                </div>
              </div>
              <div className="">
                <label className=" font-semibold">Board Type</label>
                <div className="flex gap-3">
                  <button
                    className={`py-2 w-1/2 rounded-md ${values.boardType == "ib" ? "bg-black text-white" : "bg-slate-300"}`}
                    onClick={(e) => {
                      e.preventDefault();
                      setFieldValue("boardType", "ib");
                    }}
                  >
                    IB
                  </button>
                  <button
                    className={`py-2 w-1/2 rounded-md ${values.boardType == "sb" ? "bg-black text-white" : "bg-slate-300"}`}
                    onClick={(e) => {
                      e.preventDefault();
                      setFieldValue("boardType", "sb");
                    }}
                  >
                    SB
                  </button>
                </div>
              </div>
              <div className="flex gap-5">
                <div className="w-1/2">
                  <label className=" font-semibold">Manufacturer</label>
                  <CustomDropdown
                    onChange={(val) => setFieldValue("manufacturer", val)}
                    selectedValue={values.manufacturer}
                    options={manufacturerList?.map((manu) => {
                      return { label: manu?.name, value: manu?._id };
                    })}
                  ></CustomDropdown>
                  <span className="text-red-500 text-xs absolute -bottom-4 left-0">{errors.orderDate && touched.orderDate ? errors.orderDate : ""}</span>
                </div>
                <div className="w-1/2">
                  <label className=" font-semibold">Ordered From</label>
                  <input
                    name="orderedFrom"
                    id="orderedFrom"
                    value={values.orderedFrom}
                    onChange={handleChange}
                    type="string"
                    placeholder=""
                    className="bg-[#F5F5F5] focus:ring-0 focus:outline-black font-semibold px-2 py-2 rounded-md w-full"
                  />
                  <span className="text-red-500 text-xs absolute -bottom-4 left-0">{errors.orderedFrom && touched.orderedFrom ? errors.orderedFrom : ""}</span>
                </div>
              </div>
              <div className="flex gap-5">
                <div className="w-1/2">
                  <label className=" font-semibold">Order Date</label>
                  <input
                    name="orderDate"
                    id="orderDate"
                    value={values.orderDate}
                    onChange={handleChange}
                    type="date"
                    placeholder=""
                    className="bg-[#F5F5F5] focus:ring-0 focus:outline-black font-semibold px-2 py-2 rounded-md w-full"
                  />
                  <span className="text-red-500 text-xs absolute -bottom-4 left-0">{errors.orderDate && touched.orderDate ? errors.orderDate : ""}</span>
                </div>
                <div className="w-1/2">
                  <label className=" font-semibold">Received Date</label>
                  <input
                    name="dateReceived"
                    id="dateReceived"
                    value={values.dateReceived}
                    onChange={handleChange}
                    type="date"
                    placeholder=""
                    className="bg-[#F5F5F5] focus:ring-0 focus:outline-black font-semibold px-2 py-2 rounded-md w-full"
                  />
                  <span className="text-red-500 text-xs absolute -bottom-4 left-0">{errors.dateReceived && touched.dateReceived ? errors.dateReceived : ""}</span>
                </div>
              </div>

              <button className="px-5 py-2 rounded-md bg-black text-white w-1/2 mt-5 m-auto" type="submit">
                Submit
              </button>
            </form>
          </div>
        </Modal>
      )}

      <div className="flex w-screen h-full overflow-auto ">
        <div className="w-3/4">
          <div className="topbar  w-full flex justify-between items-center border-b-2 pb-3 mt-3 px-10 m-auto">
            {/* <h1 className="text-xl font-semibold"></h1> */}
            <div className="gap-5 flex flex-wrap justify-start">
              <input
                type="text"
                className="bg-[#F5F5F5] focus:ring-0 focus:outline-black font-semibold px-2 py-2 rounded-md "
                value={filter.boardNumber}
                onChange={(val) =>
                  setFilter((prev) => {
                    return { ...prev, boardNumber: val.target.value };
                  })
                }
                placeholder="Board Number"
              />
              <input
                type="number"
                className="bg-[#F5F5F5] focus:ring-0 focus:outline-black font-semibold px-2 py-2 rounded-md"
                value={filter.batch}
                onChange={(val) =>
                  setFilter((prev) => {
                    return { ...prev, batch: val.target.value };
                  })
                }
                placeholder="Batch"
              />
            </div>
            <div className=" gap-5 flex flex-wrap justify-end">
              <button
                onClick={() => {
                  setFilter((prev) => {
                    return { ...filter, boardType: "all" };
                  });
                }}
                className={`rounded-lg border-[1px] h-10 px-5 border-black font-semibold ${filter.boardType == "all" ? "bg-black text-white" : ""}`}
              >
                All
              </button>
              <button
                onClick={() => {
                  setFilter((prev) => {
                    return { ...filter, boardType: "ib" };
                  });
                }}
                className={`rounded-lg border-[1px] h-10 px-5 border-black font-semibold ${filter.boardType == "ib" ? "bg-black text-white" : ""}`}
              >
                IB
              </button>
              <button
                onClick={() => {
                  setFilter((prev) => {
                    return { ...filter, boardType: "sb" };
                  });
                }}
                className={`rounded-lg border-[1px] h-10 px-5 border-black font-semibold ${filter.boardType == "sb" ? "bg-black text-white" : ""}`}
              >
                SB
              </button>
              <div className="h-10 border-r border-r-slate-400 bg-transparent "></div>
              <button
                onClick={() => {
                  setFilter((prev) => {
                    return { ...filter, boardStatus: "all" };
                  });
                }}
                className={`rounded-lg border-[1px] h-10 px-5 border-black font-semibold ${filter.boardStatus == "all" ? "bg-black text-white" : ""}`}
              >
                All
              </button>
              <button
                onClick={() => {
                  setFilter((prev) => {
                    return { ...filter, boardStatus: "rejected" };
                  });
                }}
                className={`rounded-lg border-[1px] h-10 px-5 border-black font-semibold ${filter.boardStatus == "rejected" ? "bg-black text-white" : ""}`}
              >
                Rejected
              </button>
            </div>
          </div>
          <div className="mainBody w-full grid grid-cols-4 gap-3 mt-5 px-5 ">
            {boards &&
              boards.map((board) => {
                return (
                  <div
                    onClick={() => {
                      setActiveBoard(board);
                    }}
                    className={`shadow-container px-4 py-2 rounded-lg cursor-pointer
                      ${activeBoard?._id == board?._id ? "border-2 border-gray-600" : ""}
                      ${
                        board.boardStatus == "rejected"
                          ? "bg-red-100"
                          : (board.boardType == "sb" && board.pmSensor && board.asb) || (board.boardType == "ib" && board.asb && board.esp)
                          ? "bg-green-100"
                          : "bg-gray-100"
                      } `}
                  >
                    <div className="flex justify-between">
                      <h1 className="text-left font-xl font-bold">{board.boardNumber}</h1>
                      <h3>{board.boardType}</h3>
                    </div>
                    <div className="flex justify-between">
                      <h3>Batch - {board.batch}</h3>
                      <h3 className={`text-left `}>Status - {board.boardStatus}</h3>
                    </div>
                    <h3 className="text-left">Order Date - {moment(board.orderDate).format("do MMM YYYY")}</h3>
                    <h3 className="text-left">Date received -{moment(board.dateReceived).format("do MMM YYYY")}</h3>
                    <div className="flex justify-end">
                      <button className="bg-black text-white w-auto rounded-md px-3 py-2 mt-3 ">Detailed View</button>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="bg-black text-white flex w-1/4">
          <div className="topbar  w-full justify-between mt-5 px-10 m-auto">
            <h1 className="text-xl font-semibold text-left">Components</h1>
            {activeBoard && activeBoard?.boardType == "ib" ? (
              <div className="">
                <div className="flex mt-10 justify-between items-center border-b border-b-slate-200 pb-6">
                  <h1>IB - {activeBoard.boardNumber}</h1>
                  <button className="bg-slate-200 px-3 py-2 rounded-md text-black">Reject Board</button>
                </div>
                <div
                  onClick={() => setActiveBoard(activeBoard?.sbBoard)}
                  className="flex pt-6 justify-between items-center border-b border-b-slate-200 pb-6 cursor-pointer "
                >
                  <h1>SB Board - {activeBoard?.sbBoard?.boardNumber}</h1>
                  {!activeBoard?.sbBoard ? (
                    <div className="">add component</div>
                  ) : (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        console.log("asd");
                      }}
                      className="bg-slate-200 px-3 py-2 rounded-md text-black"
                    >
                      Reject Component
                    </button>
                  )}
                </div>
                <div onClick={() => {}} className="flex pt-6 justify-between items-center border-b border-b-slate-200 pb-6 cursor-pointer ">
                  <h1>ESP - {activeBoard?.esp?.boardNumber}</h1>
                  {!activeBoard?.esp ? (
                    <div className="text-black" onClick={() => getComponentList("esp")}>
                      {/* <CustomDropdown options={components} /> */}
                      <button className="bg-white px-3 py-2 rounded-md">Add ESP</button>
                    </div>
                  ) : (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        console.log("asd");
                      }}
                      className="bg-slate-200 px-3 py-2 rounded-md text-black"
                    >
                      Reject Component
                    </button>
                  )}
                </div>
              </div>
            ) : (
              <div className="">
                <h1>SB</h1>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListBoards;
