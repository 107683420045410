import { Box, Button, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { IoIosArrowDown } from "react-icons/io";

function CustomDropdown({ selectedValue, options, onChange, placeHolder = "", background = "#f5f5f5" }) {
  console.log("options --> ", options);
  return (
    <Menu placement="bottom-start">
      <MenuButton
        // border={"1px"}
        w={"full"}
        // borderColor={"slategray"}
        h={"10"}
        textAlign={"left"}
        background={background}
        as={Button}
        rightIcon={<IoIosArrowDown />}
      >
        {placeHolder && !selectedValue && <div className="p text-gray-400 font-normal">{placeHolder}</div>}
        {options.find((option) => option.value == selectedValue)?.label}
      </MenuButton>
      <MenuList width={"full"} className="w-full" maxH={"36"} overflowY={"scroll"}>
        {options.map((option) => (
          <MenuItem key={option.value} onClick={() => onChange(option.value)}>
            {option.label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}

export default CustomDropdown;
