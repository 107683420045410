import { useNavigate } from "react-router-dom";
import { useUserListMutation } from "../../store/services/usersApi";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import CustomDropdown from "../../components/common/CustomDropdown";
import {
  useDeviceListMutation,
  useFindDeviceByIdMutMutation,
  useDevicePerformanceReportMutation,
  useGetCompleteDeviceDataMutation,
  useDeviceUsageStatsMutation,
} from "../../store/services/deviceApi";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";

import Modal from "../../components/common/Modal";
import { useAppDispatch } from "../../store/hooks";
import { setAdmintargetUserID } from "../../store/features/authSlice";
import { useToast } from "@chakra-ui/react";

import PieGraph from "../../components/common/PieChart";
import { useLocation } from "react-router-dom";
import LineGraph from "../../components/common/LineGraph";
import moment from "moment";
import PerDayData from "../../components/common/PerDayDataChart";
import { Spinner } from "@chakra-ui/react";
const PerformancePage = ({ deviceId }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  console.log("param data --> ", location.state);
  const toast = useToast();
  const [showFilterSelection, setShowFilterSelection] = useState(true);

  const [clientOptions, setClientOptions] = useState([]);
  const [deviceOptions, setDeviceOptions] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [performanceFormattedData, setPerformanceFormattedData] = useState();
  const [deviceUsage, setDeviceUsage] = useState({ dataCollectionHours: 0, cleaningHours: 0, reguralIntervals: {} });
  const [areaData, setAreaData] = useState([]);
  const [perDayUsageData, setPerDayUsageData] = useState([]);

  // const { deviceId } = useParams();
  const [dateRange, setDateRange] = useState([
    {
      startDate: moment().subtract(1, "week").toDate(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  //

  const [clientList, { data: userData, error: userError, isError: isUserError, isSuccess: isUserSuccess }] = useUserListMutation();

  const [deviceList, { data: deviceData, error: deviceError, isError: isDeviceError, isSuccess: isDeviceSuccess }] = useDeviceListMutation();
  const [getDeviceInfo, { data: deviceInfoData, error: deviceInfoError, isError: isDeviceInfoError, isSuccess: isDeviceInfoSuccess }] = useFindDeviceByIdMutMutation();

  const [
    getPerformanceReport,
    { data: performanceData, error: performanceError, isError: isperformanceError, isSuccess: isperformanceSuccess, isLoading: performanceLoading },
  ] = useDevicePerformanceReportMutation();

  const [getUsageStats, { data: usageData, error: usageError, isError: isusageError, isSuccess: isusageSuccess, isLoading: usageLoading }] =
    useDeviceUsageStatsMutation();

  const [downloadReport, { data: reportData, error: reportError, isError: isReportError, isSuccess: isReportSuccess, isLoading: reportLoading }] =
    useGetCompleteDeviceDataMutation();

  useEffect(() => {
    if (location.state && location.state.deviceParam && location.state.userParam) {
      clientList("");
      setSelectedClient(location.state.userParam);
      setSelectedDevice(location.state.deviceParam);
      setShowFilterSelection(false);
      let bodyObject = {
        body: dateRange[0],
        params: {
          deviceId: location.state.deviceParam,
        },
      };
      setPerDayUsageData([]);
      getPerformanceReport(bodyObject);
      getUsageStats(bodyObject);
      getDeviceInfo(location.state.deviceParam);
      // getAllData(bodyObject);
      // useFindDeviceByIdQuery(selectedDevice);
      setSelectedClient(location.state.userParam);
      dispatch(setAdmintargetUserID(location.state.userParam));
      deviceList();

      downloadReport({ body: { ...dateRange[0], deviceId: location.state.deviceParam }, device: location.state.deviceParam });
    } else {
      clientList("");
    }
  }, []);

  useEffect(() => {
    if (isUserSuccess) {
      setClientOptions(
        userData.map((user) => {
          return { label: user.name + " - " + user.email + ` (${user.role})`, value: user._id };
        })
      );
    }
  }, [isUserSuccess, userData]);

  useEffect(() => {
    if (isReportSuccess && isperformanceSuccess) {
      let data = reportData.excelData[0].content;
      let perDayData = {};
      for (let i = 0; i < data.length; i++) {
        let dayData = data[i];
        perDayData[dayData.time.split(" ")[0]] ? perDayData[dayData.time.split(" ")[0]].push(dayData) : (perDayData[dayData.time.split(" ")[0]] = [dayData]);
      }
      console.log("Day data = ", data);

      let activeDay = 0;
      for (let days in perDayData) {
        let todaysData = perDayData[days];
        let x = todaysData.find((val) => val.metadata?.devicePower);
        if (x) activeDay++;
      }
      let avgDataCollectionTime = ((data.length * 2) / (Object.keys(perDayData).length * 60)).toFixed(2);

      let avgCleaningTime = ((data.filter((packet) => +packet.metadata.devicePower).length * 2) / (activeDay * 60)).toFixed(2);
      let dropData = [];

      for (const days in perDayData) {
        let todaysData = perDayData[days];

        let { greatestDrop, maxObj, minObj, timeDiff, greatestDropPercent, cadr } = findGreatestDrop(todaysData);
        // console.log(greatestDrop, maxObj, minObj, timeDiff);
        dropData.push({
          greatestDrop,
          greatestDropPercent,
          maxTime: maxObj.time,
          minTime: minObj.time,
          maxPm: maxObj.p25,
          minPm: minObj.p25,
          timeDiff,
          cadr,
        });

        let usageStats = todaysData.map((data, index) => {
          if (index == 0) {
            return {
              saved_at: new Date(moment(data.time).startOf("day").toDate()).getTime(),
              powerStatus: data?.metadata?.devicePower ? 1 : 0,
            };
          }
          if (!data?.metadata?.devicePower) {
            return null;
          }
          return {
            saved_at: new Date(data.time).getTime(),
            powerStatus: data?.metadata?.devicePower ? 1 : 0,
          };
        });

        console.log("days -->", days, usageStats);
        // let tempPerDayUsage = perDayUsageData.push();
        setPerDayUsageData((val) => {
          return [...val, { date: days, usageStats: usageStats }];
        });
      }

      setDeviceUsage((val) => {
        return { ...val, dataCollectionHours: avgDataCollectionTime, cleaningHours: avgCleaningTime };
      });
      setAreaData(dropData);
    }
  }, [isReportSuccess, reportData, isperformanceSuccess]);

  useEffect(() => {
    if (isperformanceSuccess) {
      setShowFilterSelection(false);

      let data = {};
      let temp = {};
      let perDayCleaningData = 0;

      let usageHours = {};
      performanceData.deviceWorkingCount.forEach((day) => {
        //divide the cadr info based on days and calculate the max drop for each day,(with how much time it took if possible)

        // fan speed usage chart
        for (let i = 0; i < day.fanData.length; i++) {
          let speed = day.fanData[i];
          if (temp["fanUsage"]) {
            temp["fanUsage"][speed.fanMode] =
              (temp["fanUsage"][speed.fanMode] ? temp["fanUsage"][speed.fanMode] : 0) + +speed.duration.split(" ")[0] + +(+speed.duration.split(" ")[2] / 60).toFixed(2);

            temp["currentObj"] = speed;
          } else {
            temp["fanUsage"] = [];
            temp["fanUsage"][speed.fanMode] =
              (temp["fanUsage"][speed.fanMode] ? temp["fanUsage"][speed.fanMode] : 0) + +speed.duration.split(" ")[0] + +(+speed.duration.split(" ")[2] / 60).toFixed(2);

            temp["currentObj"] = speed;
          }
        }
      });
      data["fanUsage"] = Object.keys(temp.fanUsage).map((key) => {
        if (+key !== 0) {
          {
            data["totalDeviceUsage"] = (data["totalDeviceUsage"] ? data["totalDeviceUsage"] : 0) + +temp.fanUsage[key].toFixed(2);
          }
        }
        return {
          name: `${+key == 0 ? "Off" : +key == 1 ? "Silent" : +key == 2 ? "Standard" : +key == 3 ? "Turbo" : +key == 4 ? "Deep Clean" : +key == 5 ? "Auto Mode" : "Off"}`,
          value: +temp.fanUsage[key].toFixed(2),
        };
      });

      let dayBasedPerformanceData = {};
      performanceData.fanData.forEach((currEvent) => {
        dayBasedPerformanceData[currEvent.saved_at.split(" ")[0]]
          ? dayBasedPerformanceData[currEvent.saved_at.split(" ")[0]].push(currEvent)
          : (dayBasedPerformanceData[currEvent.saved_at.split(" ")[0]] = [currEvent]);
      });

      // perDayCleaningData = (data.totalDeviceUsage / performanceData.deviceWorkingCount.length).toFixed(2);

      console.log("per day cleaning data --> ", perDayCleaningData, data.totalDeviceUsage, performanceData.deviceWorkingCount);
      // setDeviceUsage((val) => {
      //   return { ...val, cleaningHours: perDayCleaningData };
      // });
      setPerformanceFormattedData(data);
    }
  }, [isperformanceSuccess, performanceData]);

  useEffect(() => {
    if (isDeviceSuccess) {
      setDeviceOptions(
        deviceData.map((device) => {
          return { label: device.name + " - " + device.deviceID, value: device._id };
        })
      );
    }
  }, [isDeviceSuccess, deviceData]);

  useEffect(() => {
    console.log("yay");
  }, [performanceData, isperformanceSuccess]);

  const selectClient = (client) => {
    setSelectedClient(client);
    // console.log(client);
    dispatch(setAdmintargetUserID(client));
    deviceList();
  };

  function parseTimeString(timeString) {
    const [datePart, timePart] = timeString.split(" : ");
    const [day, month, year] = datePart.split("-").map(Number);
    const [hours, minutes] = timePart.split(":").map(Number);

    return new Date(year, month - 1, day, hours, minutes);
  }

  // Function to subtract two time strings
  function subtractTime(timeString1, timeString2) {
    const date1 = parseTimeString(timeString1);
    const date2 = parseTimeString(timeString2);

    // Calculate the time difference in milliseconds
    const timeDifference = date1.getTime() - date2.getTime();

    // Convert the time difference to minutes
    const minutesDifference = Math.floor(timeDifference / (1000 * 60));

    // Calculate hours and remaining minutes
    const hours = Math.floor(minutesDifference / 60);
    const minutes = minutesDifference % 60;

    // Format the result as a string
    const resultString = `${Math.abs(minutes) == 0 ? 60 : Math.abs(minutes)} minutes`;

    return resultString;
  }

  function findGreatestDrop(arr) {
    let greatestDrop = 0;
    let maxObj = null;
    let minObj = null;

    for (let i = 0; i < arr.length - 1; i++) {
      for (let j = i + 1; j < arr.length; j++) {
        if (arr[j].p25 !== 0) {
          const timeDiffMinutes = Math.abs(getTimeDiffInMinutes(arr[i].time, arr[j].time));

          // Check if the time difference is within the allowed interval (30 minutes)
          if (timeDiffMinutes <= 60) {
            const drop = arr[i].p25 - arr[j].p25;

            if (drop > greatestDrop) {
              greatestDrop = drop;
              maxObj = arr[i];
              minObj = arr[j];
            }
          }
        }
      }
    }

    let timeDiff = subtractTime(maxObj.time, minObj.time);
    let greatestDropPercent = (((maxObj.p25 - minObj.p25) / maxObj.p25) * 100).toFixed(2);
    let cadr = (
      performanceData?.overall[0].deviceData.roomArea *
      performanceData?.overall[0].deviceData.roomHeight *
      (Math.log(maxObj.p25 / minObj.p25) / +timeDiff.split(" ")[0] - 0.005266)
    ).toFixed(2);

    return { greatestDrop, maxObj, minObj, timeDiff, greatestDropPercent, cadr };
  }

  // Function to calculate the absolute time difference in minutes
  function getTimeDiffInMinutes(timeString1, timeString2) {
    const date1 = parseTimeString(timeString1);
    const date2 = parseTimeString(timeString2);
    const timeDifference = Math.abs(date1 - date2);
    return timeDifference / (1000 * 60); // Convert milliseconds to minutes
  }

  const handleSubmit = () => {
    if (selectClient == null || selectedDevice == null) {
      toast({
        title: `Please select client and device`,
        status: "error",
        isClosable: true,
      });
    }
    let bodyObject = {
      body: dateRange[0],
      params: {
        deviceId: selectedDevice,
      },
    };
    setPerDayUsageData([]);
    getPerformanceReport(bodyObject);
    getUsageStats(bodyObject);
    getDeviceInfo(selectedDevice);
    // getAllData(bodyObject);
    // useFindDeviceByIdQuery(selectedDevice);
    downloadReport({ body: { ...dateRange[0], deviceId: selectedDevice }, device: selectedDevice });
  };

  return (
    <div className="pb-5 w-screen m-auto fixed bg-white left-0 z-50 h-screen top-0 mt-16">
      {(usageLoading || reportLoading || performanceLoading) && (
        <div className="fixed h-screen w-screen bg-[#00000030] top-0 left-0 flex justify-center items-center">
          <Spinner />
        </div>
      )}

      <div className="parent p-3">
        <div className="header flex pb-2 border-b-[1px] justify-between items-center ">
          <h1 className="text-2xl font-bold">HIVE Performance Report</h1>
          <button className="rounded-lg border-[1px] h-10 px-5 border-black font-semibold" onClick={() => setShowFilterSelection(true)}>
            Filter Select
          </button>
        </div>
        {/* <div className="bar mt-2 border-b-[1px] pb-2 ">
          {deviceInfoData && deviceInfoData.sensorData && (
            <div className="flex w-full justify-evenly items-center">
              <h4>Last Recorded Sensor Values : </h4>
              <span className="font-semibold ml-2 text-sm ">PM 1.0: {deviceInfoData.sensorData.p1} </span>
              <span className="font-semibold ml-2 text-sm ">PM 2.5: {deviceInfoData.sensorData.p25} </span>
              <span className="font-semibold ml-2 text-sm ">PM 10: {deviceInfoData.sensorData.p10} </span>
              <span className="font-semibold ml-2 text-sm ">Temperature: {deviceInfoData.sensorData.temperature} </span>
              <span className="font-semibold ml-2 text-sm ">Humidity: {deviceInfoData.sensorData.humidity} </span>
              <span className="font-semibold ml-2 text-sm ">sound: {deviceInfoData.sensorData.sound} </span>
              <span className="font-semibold ml-2 text-sm ">voc: {deviceInfoData.sensorData.voc} </span>
              <span className="font-semibold ml-2 text-sm ">co2: {deviceInfoData.sensorData.co2} </span>
            </div>
          )}
        </div> */}

        <div className="parent flex">
          <div className="left w-[39%] border-r-[1px] flex flex-col items-start pt-5 pl-5 overflow-y-scroll">
            <h1 className="text-xl ">
              Device Name: <span className="font-bold">{deviceInfoData?.name}</span>
            </h1>
            <h1 className="text-xl mt-2">
              Device ID: <span className="font-bold">{deviceInfoData?.deviceID}</span>{" "}
            </h1>
            <h1 className="text-xl mt-2">
              Pre Clean Used <span className="font-bold">{performanceData?.overall?.preCleanUsedCount ? performanceData?.overall?.preCleanUsedCount : 0}</span> times
            </h1>
            <h1 className="text-xl mt-2">
              Filter health:{" "}
              <span className="font-bold">
                {deviceInfoData?.deviceFilterStatus?.hepaPercentage}% or {deviceInfoData?.deviceFilterStatus?.hepaDaysRemaining}
              </span>{" "}
              Days Remaining
            </h1>

            {/* <h1 className="text-xl mt-2">
              Device Used For:{" "}
              <span className="font-bold">
                {(performanceFormattedData?.totalDeviceUsage / 24).toFixed(2)} Days or{" "}
                {(
                  (performanceFormattedData?.totalDeviceUsage /
                    moment.duration(moment(dateRange[0].endDate).endOf("day").diff(moment(dateRange[0].startDate).startOf("day"))).asHours()) *
                  100
                ).toFixed(2)}
                % of time
              </span>{" "}
              <br />
            </h1> */}
            <h1 className="text-xl mt-2">
              {/* {console.log(performanceFormattedData)} */}
              Device Cleaning time per day: <span className="font-bold"> {deviceUsage?.cleaningHours} Hours per Day</span>
            </h1>
            <h1 className="text-xl mt-2">
              {/* {console.log(performanceFormattedData)} */}
              Device data Collection time per day: <span className="font-bold">{deviceUsage?.dataCollectionHours} Hours</span>
            </h1>
            <div className="w-full h-80 pb-12 border-[1px] mt-2 p-3 ">
              <h1 className="text-left text-xl font-semibold ">Fan Speed Stat Pie Chart</h1>
              {/* <PieGraph data={performanceFormattedData?.fanUsage} dataKey="value" /> */}

              <PieGraph data={performanceFormattedData?.fanUsage} dataKey="value" />
            </div>
          </div>
          <div className="right w-[60%] overflow-y-scroll ">
            <h1 className="text-xl font-semibold  mt-2 ml-2">Maximum PM Drop within 1 hour Per Day Graph</h1>
            <div className="pie w-full h-[72vh] mt-2">
              <LineGraph graphData={areaData} dataKeyObject={{ value: "greatestDropPercent", label: "Greatest Drop" }}></LineGraph>
              {console.log(
                "lenghitsd -->",
                perDayUsageData,
                perDayUsageData.length,
                perDayUsageData.map((a) => a.date)
              )}
              <h1 className="font-bold text-xl my-4">Hours Used Per Day;</h1>
              {perDayUsageData.map((days) => {
                return (
                  <div className="h-14 items-center flex w-full border-[1px]">
                    <h1 className="block w-32">{days.date}</h1>
                    <div className="h-1 w-10/12 flex items-center ">
                      <PerDayData graphData={days.usageStats} dataKeyObject={{ value: "powerStatus", label: "Greatest Drop" }}></PerDayData>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {showFilterSelection && (
          <Modal modalStyle="w-auto min-w-[80%] " className="" onClose={() => setShowFilterSelection(false)}>
            {/* <h1 className="font-bold  text-xl">Filters</h1> */}

            <div className="parent flex justify-center">
              <div className="left pr-5 border-r-2 w-1/2">
                <div className="my-2">
                  <h2 className="text-left font-bold text-lg mb-2">Select Client</h2>
                  <CustomDropdown options={clientOptions} selectedValue={selectedClient} onChange={selectClient} />
                </div>

                <div className="my-2">
                  <h2 className="text-left font-bold text-lg mb-2">Select Device</h2>
                  <CustomDropdown options={deviceOptions} selectedValue={selectedDevice} onChange={(val) => setSelectedDevice(val)} />
                </div>
              </div>
              <div className="right pl-5 w-2/4">
                <div className="my-2">
                  <h2 className="text-left font-bold text-lg mb-2">Select Date Range</h2>
                  <DateRangePicker
                    onChange={(item) => setDateRange([item.selection])}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={1}
                    ranges={dateRange}
                    direction="vertical"
                    inputRanges={[]}
                    maxDate={new Date()}
                  />
                </div>
              </div>
            </div>

            <button
              className="rounded-lg border-[1px] mt-8 h-9 px-5 border-black font-semibold hover:bg-[#4e9bff] hover:text-white hover:border-none"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </Modal>
        )}
      </div>
    </div>
  );
};
export default PerformancePage;
