import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const userApi = createApi({
  reducerPath: "userApi",
  // configuring baseQuery
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL,
    prepareHeaders: (headers, { getState }) => {
      console.log("getState", getState);
      const token = JSON.parse(localStorage.getItem("user") || "").token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
        headers.set("targetUserID", getState().auth.targetUserID);
      }
    },
  }),
  endpoints: (builder) => ({
    // check if the user has room
    validateUserOverwrite: builder.mutation({
      query: (body) => {
        return {
          url: `/auth/validateUserOverwrite`,
          method: "post",
          body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    resendVerificationMail: builder.mutation({
      query: (body) => {
        return {
          url: `/auth/resendVerificationEmail`,
          method: "post",
          body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    hasRoom: builder.mutation({
      query: () => {
        return {
          url: `/user/hasRoom`,
          method: "get",
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    userList: builder.mutation({
      query: (role) => {
        return {
          url: `/user/list?role=${role}`,
          method: "get",
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    toggleNotifications: builder.mutation({
      query: (body) => {
        return {
          url: `/user/toggleNotifications`,
          method: "post",
          body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    getUserInfo: builder.mutation({
      query: () => {
        return {
          url: `/user`,
          method: "get",
          // body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
  }),
});

export const {
  useHasRoomMutation,
  useUserListMutation,
  useToggleNotificationsMutation,
  useGetUserInfoMutation,
  useResendVerificationMailMutation,
  useValidateUserOverwriteMutation,
} = userApi;
