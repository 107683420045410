import { useGetUserInfoMutation, useResendVerificationMailMutation, useValidateUserOverwriteMutation } from "../../store/services/usersApi";
import { useDeviceListMutation, useFindDeviceByIdMutMutation, useResendFilterResetEmailMutation } from "../../store/services/deviceApi";

import { useState, useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import { BiEdit } from "react-icons/bi";
import { Spinner } from "@chakra-ui/react";
import moment from "moment";
const UserDashboard = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [getUserInfo, { data: userData, isSuccess: isUserFetchSuccess }] = useGetUserInfoMutation();
  const [getDeviceList, { data: DeviceList, isSuccess: isDeviceListFetchSuccess }] = useDeviceListMutation();
  const [getDeviceInfo, { data: DeviceInfo, isSuccess: isDeviceFetchSuccess }] = useFindDeviceByIdMutMutation();
  const [sendVerificationMail, { data: MailInfo, isSuccess: isMailSuccess, isLoading: mailLoading }] = useResendVerificationMailMutation();
  const [validateOverwrite, { data: ValidateData, isSuccess: isValidateSuccess, isLoading: validateLoading }] = useValidateUserOverwriteMutation();
  const [sendFilterResetMail, { data: filterEmailData, isSuccess: isFilterEmailSuccess, isLoading: filterResetLoading }] = useResendFilterResetEmailMutation();

  const [activeDevice, setActiveDevice] = useState("");

  useEffect(() => {
    getUserInfo();
    getDeviceList();
  }, []);

  useEffect(() => {
    if (isFilterEmailSuccess) {
      toast({
        status: "success",
        title: "Filter Reset Email Sent Successfully",
      });
      // getUserInfo();
      getDeviceInfo(activeDevice);
    }
  }, [isFilterEmailSuccess, filterEmailData]);

  useEffect(() => {
    if (isMailSuccess) {
      toast({
        status: "success",
        title: "User Verified Successfully",
      });
      getUserInfo();
    }
  }, [isValidateSuccess, ValidateData]);

  useEffect(() => {
    if (isMailSuccess) {
      toast({
        status: "success",
        title: "Mail Sent Successfully",
      });
    }
  }, [isMailSuccess, MailInfo]);

  useEffect(() => {
    if (isDeviceListFetchSuccess) {
      if (activeDevice == "" && DeviceList.length > 0) {
        setActiveDevice(DeviceList[0]._id);
      }
    }
  }, [isDeviceListFetchSuccess, DeviceList]);

  useEffect(() => {
    if (activeDevice) {
      getDeviceInfo(activeDevice);
    }
  }, [activeDevice]);

  return (
    <div className={`pb-5 w-screen m-auto fixed bg-white left-0 z-50 h-screen top-0 mt-16`}>
      <div className="flex justify-between pr-10 items-center">
        <h1 className="text-left text-xl pl-5 font-bold py-3">User Dashboard</h1>
        <button
          onClick={() => navigate(`/performancePage/`, { state: { deviceParam: activeDevice, userParam: userData._id } })}
          className="border-black border h-10 rounded-md  px-4 "
        >
          Performance Report
        </button>
      </div>
      {(mailLoading || validateLoading || filterResetLoading) && (
        <div className="fixed h-screen w-screen bg-[#00000030] top-0 left-0 flex justify-center items-center">
          <Spinner />
        </div>
      )}
      <div className="flex gap-3 px-3">
        {userData && (
          <div className={`w-2/6 flex flex-col gap-2 max-h-[80vh] ${userData.isActive ? "bg-green-100" : "bg-red-100"} rounded-md overflow-y-auto text-left px-5 py-3`}>
            <h1 className="font-bold flex gap-3 items-center">
              {userData.name} <BiEdit />
            </h1>
            <h1>
              Last Data Received On - <span className="font-bold"> {userData?.lastDataReceived ? moment(userData?.lastDataReceived).format("lll") : "Never"} </span>
            </h1>
            <h1>
              Email <span className="font-bold">{userData.email} </span>
            </h1>
            <h1>
              Phone <span className="font-bold">{userData.phoneNumber} </span>
            </h1>
            <h1>
              Account Verified <span className="font-bold">{userData.verified ? "✔️" : "❌"} </span>
            </h1>
            {!userData.verified && (
              <div className="flex gap-2">
                <button
                  onClick={() => {
                    sendVerificationMail({ email: userData.email });
                  }}
                  className="bg-black rounded-md px-3 py-2 text-white text-xs "
                >
                  Resend Verification Email
                </button>
                <button
                  onClick={() => {
                    validateOverwrite({ email: userData.email });
                  }}
                  className="bg-high rounded-md px-3 py-2 text-white text-xs "
                >
                  Verify Email
                </button>
              </div>
            )}
            {userData.isBetaUser ? (
              <h1>Is Beta User ✔️</h1>
            ) : (
              <div className="">{/* <button className="bg-high rounded-md px-3 py-2 text-white text-xs mt-2 mb-4">Enroll In Beta</button> */}</div>
            )}
            <h1>
              Timezone - <span className="font-bold">{userData.timeZone} </span>
            </h1>
            <h1>
              Notification Interval <span className="font-bold">1 Per {userData.notificationInterval} </span>
            </h1>
            <h1>Notification Status</h1>
            <div className="grid grid-cols-2 gap-1">
              <h1>PM 1.0</h1>
              <input disabled={true} checked={userData.notification.p1} type="checkbox" />
              <h1>PM 2.5</h1>
              <input disabled={true} checked={userData.notification.p25} type="checkbox" />
              <h1>PM 10</h1>
              <input disabled={true} checked={userData.notification.p10} type="checkbox" />
              <h1>Temperature</h1>
              <input disabled={true} checked={userData.notification.temperature} type="checkbox" />
              <h1>Humidity</h1>
              <input disabled={true} checked={userData.notification.humidity} type="checkbox" />
              <h1>Co2</h1>
              <input disabled={true} checked={userData.notification.co2} type="checkbox" />
              <h1>VOC</h1>
              <input disabled={true} checked={userData.notification.voc} type="checkbox" />
              <h1>Sound</h1>
              <input disabled={true} checked={userData.notification.sound} type="checkbox" />
            </div>
            <div className="flex justify-center">
              <button onClick={() => navigate("/dashboard")} className="bg-black rounded-md w-full px-3 py-2 text-white text-xs mt-5 ">
                View As User
              </button>
            </div>
          </div>
        )}
        {DeviceList && (
          <div className="w-2/6 flex flex-col gap-2 max-h-[80vh] bg-gray-100 rounded-md overflow-y-auto text-left px-5 py-3">
            {DeviceList.length > 0 ? (
              <div className="gap-2 flex flex-col">
                {DeviceList.map((device) => {
                  return (
                    <div
                      key={device._id}
                      onClick={() => setActiveDevice(device._id)}
                      className={`w-full  cursor-pointer rounded-md px-2 py-3 ${activeDevice == device._id ? "bg-slate-400" : "bg-slate-200"}`}
                    >
                      <div className="flex justify-between">
                        <div className="">
                          <h1 className="font-bold">{device.name}</h1>
                          <div
                            onClick={() => {
                              navigator.clipboard.writeText(device.deviceID);
                              toast({
                                status: "success",
                                description: `IMEI Copied (${device.deviceID})`,
                                isClosable: true,
                                duration: 5000,
                              });
                            }}
                            className="text-sm cursor-pointer"
                          >
                            <h1>IMEI - {device.deviceID}</h1>
                          </div>
                        </div>
                        <div className="">
                          <h3>FW - {device.firmwareVersion || "NA"}</h3>
                          <h3>HW - {device.hardwareVersion || "NA"}</h3>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <h1>No Devices Found</h1>
            )}
          </div>
        )}
        {DeviceInfo && (
          <div className="w-2/6 flex flex-col gap-2 max-h-[80vh] bg-gray-100 rounded-md overflow-y-auto text-left px-5 py-3">
            <h1 className="font-bold text-xl">Device Information</h1>
            <div className="">
              <h1>Filter Health</h1>
              <div className="grid grid-cols-2">
                <div className="">
                  <h1>ACF</h1>
                  <h2 className="font-bold ">
                    {DeviceInfo?.deviceFilterStatus?.acfPercentage}% ({DeviceInfo.deviceFilterStatus?.acfDaysRemaining} days)
                  </h2>
                </div>
                <div className="">
                  <h1>HEPA </h1>
                  <h2 className="font-bold ">
                    {DeviceInfo?.deviceFilterStatus?.hepaPercentage}% ({DeviceInfo.deviceFilterStatus?.hepaDaysRemaining} days)
                  </h2>
                </div>
              </div>
            </div>
            <h1>
              Filter Status Email Sent{" "}
              {DeviceInfo.filterEmailSent ? `(on ${DeviceInfo.lastFilterEmailSentOn ? moment(DeviceInfo.lastFilterEmailSentOn).format("lll") : "N/A"}) ✔️` : "❌"}{" "}
            </h1>
            <h1>
              Hours Used Since Last Filter Change -{" "}
              <span className="font-bold">
                {DeviceInfo.totalUsageFromFilterReset
                  ? `${Math.round(DeviceInfo.totalUsageFromFilterReset)} Hours (${Math.round(DeviceInfo.totalUsageFromFilterReset / 24)} days)`
                  : "N/A"}
              </span>
            </h1>
            {DeviceInfo?.deviceFilterStatus?.hepaPercentage < 10 && (
              <div className="flex justify-end">
                <button
                  onClick={() => {
                    sendFilterResetMail({
                      user: userData._id,
                      device: DeviceInfo._id,
                    });
                  }}
                  className="bg-black rounded-md px-3 py-2 text-white text-xs"
                >
                  Resend Filter Reset Email
                </button>
              </div>
            )}
            {/* <h1 className="font-bold">Fan</h1>
            <div className="grid grid-cols-2">
              <h1>Left Fan Speed %</h1>
              <h1>Right Fan Speed %</h1>
            </div> */}
            <h1>Current Power Status - {DeviceInfo.powerStatus ? "✔️" : "❌"} </h1>
            <h1>Current Display Status - {DeviceInfo.displayStatus ? "✔️" : "❌"} </h1>
            <h1>
              Current Display Parameter -{" "}
              <span className="font-bold">
                {DeviceInfo.displayValue == "01"
                  ? "PM 1.0"
                  : DeviceInfo.displayValue == "02"
                  ? "PM 2.5"
                  : DeviceInfo.displayValue == "03"
                  ? "PM 10"
                  : DeviceInfo.displayValue == "04"
                  ? "Temperature"
                  : DeviceInfo.displayValue == "05"
                  ? "Humidity"
                  : DeviceInfo.displayValue == "06"
                  ? "Co2"
                  : DeviceInfo.displayValue == "07"
                  ? "VOC"
                  : DeviceInfo.displayValue == "08"
                  ? "Sound"
                  : "Unsynced ❌"}
              </span>
            </h1>
            <h1>
              Current Fan Speed -{" "}
              <span className="font-bold">
                {DeviceInfo.fanMode == "01"
                  ? "Silent"
                  : DeviceInfo.fanMode == "02"
                  ? "Standard"
                  : DeviceInfo.fanMode == "03"
                  ? "Turbo"
                  : DeviceInfo.fanMode == "04"
                  ? "Deep Clean"
                    ? DeviceInfo.fanMode == "05"
                    : "Auto"
                  : "Unsynced ❌"}
              </span>
            </h1>
            <h1>In PreClean - {DeviceInfo.room.preClean ? "✔️" : "❌"}</h1>
            <h1 className="font-semibold text-center">Last Sensor Values</h1>
            <div className="grid grid-cols-4 gap-y-3">
              <div className="flex flex-col items-center">
                <h1 className="text-sm">PM 1.0</h1>
                <h2 className="font-bold">
                  {DeviceInfo.sensorData?.p1} {Math.round(DeviceInfo.sensorData?.p1) ? "µg/m³" : "N/A"}
                </h2>
              </div>
              <div className="flex flex-col items-center">
                <h1 className="text-sm">PM 2.5</h1>
                <h2 className="font-bold">
                  {Math.round(DeviceInfo.sensorData?.p25)} {Math.round(DeviceInfo.sensorData?.p25) ? "µg/m³" : "N/A"}
                </h2>
              </div>
              <div className="flex flex-col items-center">
                <h1 className="text-sm">PM 10</h1>
                <h2 className="font-bold">
                  {Math.round(DeviceInfo.sensorData?.p10)} {Math.round(DeviceInfo.sensorData?.p10) ? "µg/m³" : "N/A"}
                </h2>
              </div>
              <div className="flex flex-col items-center">
                <h1 className="text-sm">Temperature</h1>
                <h2 className="font-bold">
                  {Math.round(DeviceInfo.sensorData?.temperature)} {Math.round(DeviceInfo.sensorData?.temperature) ? "°C" : "N/A"}
                </h2>
              </div>
              <div className="flex flex-col items-center">
                <h1 className="text-sm">Humidity</h1>
                <h2 className="font-bold">
                  {Math.round(DeviceInfo.sensorData?.humidity)} {Math.round(DeviceInfo.sensorData?.humidity) ? "%" : "N/A"}
                </h2>
              </div>
              <div className="flex flex-col items-center">
                <h1 className="text-sm">Co2</h1>
                <h2 className="font-bold">
                  {Math.round(DeviceInfo.sensorData?.co2)} {Math.round(DeviceInfo.sensorData?.co2) ? "ppb" : "N/A"}
                </h2>
              </div>
              <div className="flex flex-col items-center">
                <h1 className="text-sm">VOC</h1>
                <h2 className="font-bold">
                  {Math.round(DeviceInfo.sensorData?.voc)} {Math.round(DeviceInfo.sensorData?.voc) ? "Unit" : "N/A"}
                </h2>
              </div>
              <div className="flex flex-col items-center">
                <h1 className="text-sm">Sound</h1>
                <h2 className="font-bold">
                  {Math.round(DeviceInfo.sensorData?.sound)} {Math.round(DeviceInfo.sensorData?.sound) ? "dBA" : "N/A"}
                </h2>
              </div>
            </div>
            <h1 className="font-semibold text-center mt-10">Last Month Avg. Sensor Values</h1>
            <div className="grid grid-cols-4 gap-y-3">
              <div className="flex flex-col items-center">
                <h1 className="text-sm">PM 1.0</h1>
                <h2 className="font-bold">
                  {Math.round(DeviceInfo.avgDataForMonth?.p1)} {Math.round(DeviceInfo.avgDataForMonth?.p1) ? "µg/m³" : "N/A"}
                </h2>
              </div>
              <div className="flex flex-col items-center">
                <h1 className="text-sm">PM 2.5</h1>
                <h2 className="font-bold">
                  {Math.round(DeviceInfo.avgDataForMonth?.p25)} {Math.round(DeviceInfo.avgDataForMonth?.p25) ? "µg/m³" : "N/A"}
                </h2>
              </div>
              <div className="flex flex-col items-center">
                <h1 className="text-sm">PM 10</h1>
                <h2 className="font-bold">
                  {Math.round(DeviceInfo.avgDataForMonth?.p10)} {Math.round(DeviceInfo.avgDataForMonth?.p10) ? "µg/m³" : "N/A"}
                </h2>
              </div>
              <div className="flex flex-col items-center">
                <h1 className="text-sm">Temperature</h1>
                <h2 className="font-bold">
                  {Math.round(DeviceInfo.avgDataForMonth?.temperature)} {Math.round(DeviceInfo.avgDataForMonth?.temperature) ? "°C" : "N/A"}
                </h2>
              </div>
              <div className="flex flex-col items-center">
                <h1 className="text-sm">Humidity</h1>
                <h2 className="font-bold">
                  {Math.round(DeviceInfo.avgDataForMonth?.humidity)} {Math.round(DeviceInfo.avgDataForMonth?.humidity) ? "%" : "N/A"}
                </h2>
              </div>
              <div className="flex flex-col items-center">
                <h1 className="text-sm">Co2</h1>
                <h2 className="font-bold">
                  {Math.round(DeviceInfo.avgDataForMonth?.co2)} {Math.round(DeviceInfo.avgDataForMonth?.co2) ? "ppb" : "N/A"}
                </h2>
              </div>
              <div className="flex flex-col items-center">
                <h1 className="text-sm">VOC</h1>
                <h2 className="font-bold">
                  {Math.round(DeviceInfo.avgDataForMonth?.voc)} {Math.round(DeviceInfo.avgDataForMonth?.voc) ? "Unit" : "N/A"}
                </h2>
              </div>
              <div className="flex flex-col items-center">
                <h1 className="text-sm">Sound</h1>
                <h2 className="font-bold">
                  {Math.round(DeviceInfo.avgDataForMonth?.sound)} {Math.round(DeviceInfo.avgDataForMonth?.sound) ? "dBA" : "N/A"}
                </h2>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserDashboard;
