import { useEffect, useState } from "react";
import { useListManufacturerMutation, useCreateManufacturerMutation, useEditManufacturerMutation } from "../../../store/services/manufacturerApi";
import { useNavigate } from "react-router-dom";
import Modal from "../../../components/common/Modal";
import { useFormik } from "formik";
import { useToast } from "@chakra-ui/react";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
const initialValues = {
  name: "",
  description: "",
  website: "",
  phoneNumber: "",
  email: "",
};

const ListManufacturer = () => {
  const [listManufacturer, { data: manufacturerList, isSuccess: manufacturerListSuccess }] = useListManufacturerMutation();

  const [createManufacturer, { data: createData, isSuccess: createSuccess, isError: isCreateError, error: createError }] = useCreateManufacturerMutation();

  const toast = useToast();
  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: initialValues,
    // validationSchema: roomsSchema,
    onSubmit: (values, action) => {
      console.log(values);

      createManufacturer(values);
      action.resetForm();
    },
  });

  const [addModal, setAddModal] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    listManufacturer();
  }, []);

  useEffect(() => {
    if (isCreateError) {
      //   listManufacturer();
      toast({
        description: createError.data.message,
        isClosable: true,
        duration: 5000,
        status: "error",
      });
      //   setAddModal(false);
    }
  }, [isCreateError, createError]);
  useEffect(() => {
    if (createSuccess) {
      listManufacturer();
      toast({
        description: "Manufacturer created successfully!",
        isClosable: true,
        duration: 5000,
        status: "success",
      });
      setAddModal(false);
    }
  }, [createData, createSuccess]);

  return (
    <div className={`pb-5 w-screen m-auto fixed bg-white left-0 z-50 h-screen top-0 mt-16`}>
      {addModal && (
        <Modal
          onClose={() => {
            setAddModal(false);
          }}
        >
          <h1 className="font-semibold mb-4 text-lg">Add Manufacturer</h1>

          <div className="inpGrp text-left relative">
            <form className="gap-4 flex flex-col " onSubmit={handleSubmit}>
              <div className="">
                <label className=" font-semibold">Name Of Manufacturer</label>
                <input
                  name="name"
                  id="name"
                  value={values.name}
                  onChange={handleChange}
                  type="text"
                  placeholder=""
                  className="bg-[#F5F5F5] focus:ring-0 focus:outline-black font-semibold px-2 py-2 rounded-md w-full"
                />
                <span className="text-red-500 text-xs absolute -bottom-4 left-0">{errors.name && touched.name ? errors.name : ""}</span>
              </div>
              <div className="">
                <label className=" font-semibold">Website</label>
                <input
                  name="website"
                  id="website"
                  value={values.website}
                  onChange={handleChange}
                  type="text"
                  placeholder=""
                  className="bg-[#F5F5F5] focus:ring-0 focus:outline-black font-semibold px-2 py-2 rounded-md w-full"
                />
                <span className="text-red-500 text-xs absolute -bottom-4 left-0">{errors.website && touched.website ? errors.website : ""}</span>
              </div>
              <div className="">
                <label className=" font-semibold">Phone</label>
                <input
                  name="phoneNumber"
                  id="phoneNumber"
                  value={values.phoneNumber}
                  onChange={handleChange}
                  type="text"
                  placeholder=""
                  className="bg-[#F5F5F5] focus:ring-0 focus:outline-black font-semibold px-2 py-2 rounded-md w-full"
                />
                <span className="text-red-500 text-xs absolute -bottom-4 left-0">{errors.phoneNumber && touched.phoneNumber ? errors.phoneNumber : ""}</span>
              </div>
              <div className="">
                <label className=" font-semibold">Email</label>
                <input
                  name="email"
                  id="email"
                  value={values.email}
                  onChange={handleChange}
                  type="text"
                  placeholder=""
                  className="bg-[#F5F5F5] focus:ring-0 focus:outline-black font-semibold px-2 py-2 rounded-md w-full"
                />
                <span className="text-red-500 text-xs absolute -bottom-4 left-0">{errors.email && touched.email ? errors.email : ""}</span>
              </div>
              <div className="">
                <label className=" font-semibold">description</label>
                <input
                  name="description"
                  id="description"
                  value={values.description}
                  onChange={handleChange}
                  type="text"
                  placeholder=""
                  className="bg-[#F5F5F5] focus:ring-0 focus:outline-black font-semibold px-2 py-2 rounded-md w-full"
                />
                <span className="text-red-500 text-xs absolute -bottom-4 left-0">{errors.description && touched.description ? errors.description : ""}</span>
              </div>

              <button className=" bg-black text-white w-1/2 m-auto py-2 rounded-md">Submit</button>
            </form>
          </div>
        </Modal>
      )}

      <div className="topbar  mb-5 flex justify-between items-center border-b-2 pb-3 mt-3 px-10 m-auto">
        <div className="flex items-center">
          <MdOutlineKeyboardArrowLeft className="text-4xl" onClick={() => navigate(-1)} />
          <h1 className="text-xl font-semibold">Manufacturers</h1>
        </div>
        <div className=" gap-5 flex flex-wrap justify-end">
          <button onClick={() => setAddModal(true)} className="rounded-lg border-[1px] h-10 px-5 border-black font-semibold">
            Add Manufacturer
          </button>
        </div>
      </div>

      <div className="px-5">
        <div className="grid grid-cols-3 gap-3">
          {manufacturerList &&
            manufacturerList.map((manufacturer) => {
              return (
                <div
                  key={manufacturer._id}
                  className={`container cursor-pointer relative mt-3 px-4 py-3.5 m-auto shadow-container h-[7.3rem]  rounded-2xl border border-opacity-10`}
                >
                  <h4 className="font-semibold text-left">{manufacturer.name}</h4>
                  <p className="text-slate-500 text-xs text-left font-light ml-1"> website - {manufacturer.website} </p>
                  <p className="text-slate-500 text-xs text-left font-light ml-1">Email - {manufacturer?.email}</p>
                  <p className="text-slate-500 text-xs text-left font-light ml-1">Phone Number - {manufacturer.phoneNumber} </p>
                  <p className="text-slate-500 text-xs text-left font-light ml-1">{manufacturer.description} </p>
                  {/* <p className=" text-slate-500 text-left text-xs">{user.roomObject.name}</p> */}

                  {/* <img className="w-1/2 max-w-[200px] mt-1" src={hiveImg} alt="" /> */}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default ListManufacturer;
