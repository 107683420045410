import { useNavigate } from "react-router-dom";
import Modal from "../../components/common/Modal";
import { useEffect, useState } from "react";
import { useGetFirmwareListMutation, useUploadFileMutation, useCreateFirmwareMutation } from "../../store/services/firmwareApi";
import CustomDropdown from "../../components/common/CustomDropdown";
import { useToast } from "@chakra-ui/react";

const Firmwares = () => {
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);

  const [getFirmwareList, { isSuccess: listSuccess, isError: firmwareError, data: firmwareList, isLoading: firmwareLoading }] = useGetFirmwareListMutation();
  const [createFirmware, { isSuccess: createSuccess, isError: firmwareCreateError, data: firmwareCreateData, isLoading: firmwareCreateLoading }] =
    useCreateFirmwareMutation();
  const [uploadFile, { isSuccess: uploadSuccess, isError: uploadError, isLoading: uploadLoading, data: fileData }] = useUploadFileMutation();

  const [formData, setFormData] = useState({});
  const [file, setFile] = useState();

  const toast = useToast();
  useEffect(() => {
    console.log("formData", formData, file);
  }, [formData, file]);

  useEffect(() => {
    getFirmwareList();
  }, []);

  useEffect(() => {
    if (createSuccess && firmwareCreateData) {
      toast({
        title: "firmware updated successfully",
        status: "success",
        isClosable: true,
        duration: 5000,
      });
      getFirmwareList();
      setOpenModal(false);
    }
  }, [createSuccess, firmwareCreateData]);

  const onSubmit = async (e) => {
    e.preventDefault();

    console.log(`asdass`, !file || !formData.firmwareVersion);
    if (!file || !formData.firmwareVersion) {
      toast({
        title: "Please upload an OTA File and give a Firmware Version",
        duration: 5000,
        isClosable: true,
        status: "error",
      });
      return;
    }

    let form = new FormData();
    form.append("file", file);
    console.log("form data --> ", form);

    let fileResponse = await uploadFile(form);
    console.log("file response --> ", fileResponse);

    let requestBody = {
      firmwareVersion: formData.firmwareVersion,
      firmwareDownloadUrl: fileResponse.data.filename,
    };
    if (formData.availableFeatures) {
      requestBody["availableFeatures"] = formData.availableFeatures.split(",");
    }
    if (formData.otaMethodsSupported) {
      requestBody["otaMethodsSupported"] = formData.otaMethodsSupported;
    }
    if (formData.prevFirmwareId) {
      requestBody["prevFirmwareId"] = formData.prevFirmwareId;
    }
    if (formData.changeLogs) {
      requestBody["changeLogs"] = formData.changeLogs;
    }

    createFirmware(requestBody);
  };

  return (
    <div className={`pb-5 w-screen m-auto fixed bg-white left-0 z-50 h-screen top-0 mt-16 `}>
      {openModal && (
        <Modal
          modalStyle="w-auto"
          onClose={() => {
            setOpenModal(false);
          }}
        >
          <form onSubmit={onSubmit} action="">
            <div className="flex mt-5 flex-col gap-5">
              <div className="grid grid-cols-2 ">
                <label htmlFor="" className="">
                  Firmware Version
                </label>
                <input
                  type="text"
                  value={formData.firmwareVersion}
                  onChange={(e) =>
                    setFormData((prev) => {
                      return {
                        ...prev,
                        firmwareVersion: e.target.value,
                      };
                    })
                  }
                  className="rounded border border-black"
                />
              </div>
              <div className="grid grid-cols-2 ">
                <label htmlFor="" className="">
                  Changelogs
                </label>
                <textarea
                  value={formData.changeLogs}
                  onChange={(e) =>
                    setFormData((prev) => {
                      return {
                        ...prev,
                        changeLogs: e.target.value,
                      };
                    })
                  }
                  className="rounded border border-black"
                  name=""
                  id=""
                ></textarea>{" "}
              </div>
              <div className="grid grid-cols-2 ">
                <label htmlFor="" className="">
                  New Features (Comma Seperated)
                </label>
                <input
                  onChange={(e) =>
                    setFormData((prev) => {
                      return {
                        ...prev,
                        availableFeatures: e.target.value,
                      };
                    })
                  }
                  type="text"
                  className="rounded border border-black"
                />
              </div>
              <div className="grid grid-cols-2 ">
                <label htmlFor="" className="">
                  Is Https OTA Supported?
                </label>
                <input
                  type="checkbox"
                  onClick={(e) => {
                    setFormData((prev) => {
                      return {
                        ...prev,
                        otaMethodsSupported: e.target.checked ? ["mqtt", "https"] : ["mqtt"],
                      };
                    });
                  }}
                  className="rounded border border-black"
                />
              </div>

              {firmwareList && firmwareList.length > 0 && (
                <div className="grid grid-cols-2 ">
                  <label htmlFor="" className="">
                    Previous Firmware
                  </label>
                  <CustomDropdown
                    selectedValue={formData.prevFirmwareId}
                    onChange={(val) => {
                      setFormData((prev) => {
                        return {
                          ...prev,
                          prevFirmwareId: val,
                        };
                      });
                    }}
                    options={firmwareList.map((firmware) => {
                      return {
                        value: firmware._id,
                        label: firmware.firmwareVersion,
                      };
                    })}
                  />
                </div>
              )}
              <div className="grid grid-cols-2 ">
                <label htmlFor="" className="">
                  Upload Firmware
                </label>
                <input
                  onChange={(e) => {
                    setFile(() => {
                      return e.target.files[0];
                    });
                  }}
                  type="file"
                  className="rounded border border-black"
                />
              </div>
              <button className="w-1/4 m-auto py-2 mt-3 bg-black rounded-md text-white">Submit</button>
            </div>
          </form>
        </Modal>
      )}

      <div className="topbar  mb-5 flex justify-between items-center border-b-2 pb-3 mt-3 px-10 m-auto">
        <h1 className="text-xl font-semibold">Firmwares</h1>
        <div className=" gap-5 flex flex-wrap justify-end">
          <button onClick={() => setOpenModal(true)} className="rounded-lg border-[1px] h-10 px-5 border-black font-semibold">
            Add New Firmware
          </button>
        </div>
      </div>

      {firmwareList && firmwareList.length > 0 && (
        <div className="w-full grid grid-cols-4 gap-5 px-10">
          {firmwareList.map((firmware) => {
            return (
              <div className="shadow-container rounded-md px-5 py-3">
                <div className="flex">
                  <h2 className="font-bold">Firmware Version - </h2>
                  <p className="text-left "> {firmware.firmwareVersion}</p>
                </div>
                <div className="flex">
                  <h2 className="font-bold">Change Logs - </h2>
                  <p className="text-left "> {firmware.changeLogs}</p>
                </div>
                <a href={firmware.firmwareDownloadUrl} target="_blank">
                  <button className="mt-5 px-5 py-2 bg-black text-white rounded-md">Download Firmware</button>
                </a>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Firmwares;
