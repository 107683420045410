import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const firmwareApi = createApi({
  reducerPath: "firmwareApi",
  // configuring baseQuery
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL,
    prepareHeaders: (headers, { getState }) => {
      console.log("getState", getState);
      const token = JSON.parse(localStorage.getItem("user") || "").token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
        headers.set("targetUserID", getState().auth.targetUserID);
      }
    },
  }),
  endpoints: (builder) => ({
    // check if the user has room
    getFirmwareList: builder.mutation({
      query: () => {
        return {
          url: `/firmware/`,
          method: "get",
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    createFirmware: builder.mutation({
      query: (body) => {
        return {
          url: `/firmware/`,
          method: "post",
          body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    uploadFile: builder.mutation({
      query: (body) => {
        return {
          url: `/files/upload`,
          method: "post",
          body,
        };
      },
      transformResponse: (response) => {
        return response;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
  }),
});

export const { useGetFirmwareListMutation, useUploadFileMutation, useCreateFirmwareMutation } = firmwareApi;
