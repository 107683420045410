import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
// import { store } from "../store";
import xlsx from "json-as-xlsx";

export const deviceApi = createApi({
  reducerPath: "deviceApi",
  // configuring baseQuery
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_SERVER_URL,
    prepareHeaders: (headers, { getState }) => {
      console.log("getState 123", getState().auth.targetUserID);
      const token = JSON.parse(localStorage.getItem("user") || "").token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
        headers.set("targetUserID", getState().auth.targetUserID);
      }
    },
  }),
  endpoints: (builder) => ({
    // check if the user has room
    massExportPerformance: builder.mutation({
      query: (bodyObject) => {
        return {
          url: `/device/massPerformanceReport`,
          method: "post",
          body: bodyObject.body,
          params: bodyObject.params,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    resendFilterResetEmail: builder.mutation({
      query: (body) => {
        return {
          url: `/device/sendFilterEmail`,
          method: "post",
          body: body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    getAllData: builder.mutation({
      query: (bodyObject) => {
        return {
          url: `/device/exportEverything`,
          method: "post",
          body: bodyObject.body,
          params: bodyObject.params,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    devicePerformanceReport: builder.mutation({
      query: (bodyObject) => {
        return {
          url: `/device/performanceReport`,
          method: "post",
          body: bodyObject.body,
          params: bodyObject.params,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    deviceUsageStats: builder.mutation({
      query: (bodyObject) => {
        return {
          url: `/device/deviceUsageStatistics`,
          method: "post",
          body: bodyObject.body,
          params: bodyObject.params,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    createDevice: builder.mutation({
      query: (bodyObject) => {
        return {
          url: `/device/${bodyObject.room}`,
          method: "post",
          body: bodyObject.body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    createInternalDevice: builder.mutation({
      query: (body) => {
        return {
          url: `/device/internalDevice/`,
          method: "post",
          body: body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    createInternalDeviceBulk: builder.mutation({
      query: (body) => {
        return {
          url: `/device/bulk/internalDevice/`,
          method: "post",
          body: body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    deviceList: builder.mutation({
      query: (room) => {
        return {
          url: `/device`,
          method: "get",
          params: { room: room },
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    internalDeviceList: builder.mutation({
      query: (room) => {
        return {
          url: `/device/internalDevice`,
          method: "get",
          params: { room: room },
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    editDevice: builder.mutation({
      query: (deviceObject) => {
        return {
          url: `/device/${deviceObject.device}`,
          method: "put",
          body: deviceObject.body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    deviceControl: builder.mutation({
      query: (deviceObject) => {
        return {
          url: `/device/ctd/${deviceObject.device}`,
          method: "post",
          body: deviceObject.body,
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    findDeviceById: builder.query({
      query: (deviceId) => {
        return {
          url: `/device/${deviceId}`,
          method: "get",
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    findDeviceByIdMut: builder.mutation({
      query: (deviceId) => {
        return {
          url: `/device/${deviceId}`,
          method: "get",
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    unlinkDevice: builder.mutation({
      query: (deviceId) => {
        return {
          url: `/device/${deviceId}`,
          method: "delete",
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),

    downloadDeviceData: builder.mutation({
      query: (bodyObject) => ({
        url: `/device/downloadDeviceData/${bodyObject.device}`,
        method: "post",
        body: bodyObject.body,
      }),
      transformResponse: async (response) => {
        console.log("response --> ", response.data);

        let settings = {
          fileName: response.data.deviceId, // Name of the resulting spreadsheet
          extraLength: 3, // A bigger number means that columns will be wider
          writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
          writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
          RTL: false, // Display the columns from right-to-left (the default value is false)
        };
        xlsx(response.data.excelData, settings);

        return { data: null };
      },
      transformErrorResponse: async (error) => {
        if (error.response) {
          // The request was made and the server responded with a non-OK status
          const data = await error.response.json();
          console.error("API Error Response:", data);
          return { error: data };
        } else if (error.request) {
          // The request was made but no response was received
          console.error("No response received from the server");
          return { error: "No response received from the server" };
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error setting up the request:", error);
          return { message: error.data?.message };
        }
      },
    }),
    getCompleteDeviceData: builder.mutation({
      query: (bodyObject) => ({
        url: `/device/downloadDeviceData/${bodyObject.device}`,
        method: "post",
        body: bodyObject.body,
      }),
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
    resetFilterHealth: builder.mutation({
      query: (device) => ({
        url: `/device/resetFilter/${device}`,
        method: "post",
      }),
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response) => {
        console.log("error", response);
        return response.data;
      },
    }),
  }),
});

export const {
  useDeviceListMutation,
  useGetCompleteDeviceDataMutation,
  useFindDeviceByIdQuery,
  useFindDeviceByIdMutMutation,
  useCreateDeviceMutation,
  useEditDeviceMutation,
  useDeviceControlMutation,
  useUnlinkDeviceMutation,
  useInternalDeviceListMutation,
  useCreateInternalDeviceMutation,
  useCreateInternalDeviceBulkMutation,
  useDownloadDeviceDataMutation,
  useDevicePerformanceReportMutation,
  useGetAllDataMutation,
  useMassExportPerformanceMutation,
  useDeviceUsageStatsMutation,
  useResendFilterResetEmailMutation,
  useResetFilterHealthMutation,
} = deviceApi;
